import axios from "axios";
import authHeader from "./auth-header";
import AuthService from "./auth.service";
import { config } from "../config";

class EntranceExitChecklist {
  getUserChecklist(userId) {
    return axios
      .get(`${config["apiUrl"]}entranceExitChecklist/user/${userId}`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  saveAnswer(userId, questionId, bodyParms) {
    return axios
      .post(`${config["apiUrl"]}entranceExitChecklist/user/${userId}/question/${questionId}`, bodyParms, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status != undefined && response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }
}
export default new EntranceExitChecklist();
