<template>
  <div class="p-field p-col-12 p-md-12 p-fluid p-formgrid p-grid">
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <div class="p-field p-col-12 p-md-12">
      <h4><strong>Ficha de Integração</strong></h4>
      <div class="p-field p-col-12 p-md-12">
        <form
          class="p-col-12 p-mt-3"
          v-for="(integration, index) in integrationList"
          :key="index"
          :name="'userIntegrationForm_' + index"
          :id="'userIntegrationForm_' + index"
          :data-vv-scope="'userIntegrationForm_' + index"
          @submit.prevent="saveIntegration(index)"
        >
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-md-2">
              <span class="p-float-label">
                <InputText
                  type="text"
                  v-validate="'required'"
                  v-model="integration.former"
                  v-bind:class="[
                    {
                      'p-invalid': errors.has(
                        'userIntegrationForm_' + index + '.former'
                      )
                    },
                    'form-control'
                  ]"
                  :name="'former'"
                />
                <label for="former">Formador</label>
              </span>
              <small
                v-if="errors.has('userIntegrationForm_' + index + '.former')"
                class="p-error"
                role="alert"
              >
                Formador é obrigatório
              </small>
            </div>

            <div class="p-field p-col-12 p-md-2">
              <v-date-picker
                v-model="integration.day"
                is-required
                :masks="{
                  input: 'DD-MM-YYYY'
                }"
              >
                <template v-slot="{ inputValue, inputEvents }">
                  <span class="p-float-label">
                    <input
                      v-bind:class="[
                        {
                          'p-error': errors.has(
                            'userIntegrationForm_' + index + '.day'
                          )
                        },
                        'form-control',
                        'p-inputtext',
                        'p-component',
                        'p-filled'
                      ]"
                      :value="inputValue"
                      v-on="inputEvents"
                      :name="'day'"
                      v-validate="'required'"
                    />
                    <label for="inputtext">Dia</label>
                  </span>
                  <small
                    v-if="errors.has('userIntegrationForm_' + index + '.day')"
                    class="p-error"
                    role="alert"
                  >
                    Dia é obrigatório
                  </small>
                </template>
              </v-date-picker>
            </div>
            <div class="p-field p-col-12 p-md-1">
              <span class="p-float-label">
                <InputNumber
                  :name="'hours'"
                  suffix=" horas"
                  v-model="integration.duration"
                  v-validate="'required'"
                  v-bind:class="[
                    {
                      'p-invalid': errors.has(
                        'userIntegrationForm_' + index + '.hours'
                      )
                    },
                    'form-control'
                  ]"
                />
                <label for="hours">N. Horas</label>
              </span>
              <small
                v-if="errors.has('userIntegrationForm_' + index + '.hours')"
                class="p-error"
                role="alert"
              >
                Horas de Formação é obrigatório
              </small>
            </div>
            <div class="p-field p-col-12 p-md-6">
              <span class="p-float-label">
                <Textarea
                  type="text"
                  :autoResize="true"
                  rows="3"
                  v-model="integration.topics"
                  v-validate="'required'"
                  v-bind:class="[
                    {
                      'p-invalid': errors.has(
                        'userIntegrationForm_' + index + '.topics'
                      )
                    },
                    'form-control'
                  ]"
                  :name="'topics'"
                />
                <label>Temas abordados</label>
              </span>
              <small
                v-if="errors.has('userIntegrationForm_' + index + '.topics')"
                class="p-error"
                role="alert"
              >
                Temas abordados é obrigatório
              </small>
            </div>
            <div class="p-dialog-footer">
              <Button
                :label="integration.id == undefined ? 'Criar' : 'Gravar'"
                icon="pi pi-check"
                class="p-button-success"
                @click="saveIntegration(index)"
              />
            </div>
            <divider />
          </div>
        </form>
        <div class="p-field p-col-12 p-md-2">
          <Button
            :label="'Adicionar Novo'"
            icon="pi pi-plus"
            class="p-button-success"
            @click="addNew()"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import userService from "../services/user.service";
import { getOnlyDate } from "../helpers/helpers";
export default {
  name: "UserIntegration",
  props: ["userId"],
  data() {
    return {
      integrationList: []
    };
  },
  async created() {
    await this.getUserIntegrationlist();
  },
  mounted() {},
  methods: {
    async getUserIntegrationlist() {
      return userService.getUserIntegration(this.userId).then(response => {
        this.integrationList = response;
      });
    },
    saveIntegration(index) {
      this.$validator
        .validateAll(`userIntegrationForm_${index}`)
        .then(isValid => {
          if (!isValid) {
            return;
          }
          if (this.integrationList[index].id != undefined) {
            return this.updateUserIntegration(index);
          }
          return this.saveNew(index);
        });
    },
    updateUserIntegration(index) {
      let bodyParms = {
        former: this.integrationList[index].former,
        duration: this.integrationList[index].duration,
        topics: this.integrationList[index].topics,
        day:
          this.integrationList[index].day instanceof Date
            ? getOnlyDate(this.integrationList[index].day)
            : this.integrationList[index].day
      };
      return userService
        .updateUserIntegration(this.integrationList[index].id, bodyParms)
        .then(response => {
          if (!response) {
            return this.$toast.add({
              severity: "error",
              summary: "Erro",
              detail: "Ocorreu um erro ao gravar",
              life: 3000
            });
          }

          return this.$toast.add({
            severity: "success",
            summary: "Integração Alterada",
            detail: "A integração foi alterado com sucesso",
            life: 3000
          });
        });
    },
    addNew() {
      let newData = {
        former: null,
        duration: null,
        topics: null,
        day: null
      };

      return this.integrationList.push(newData);
    },
    saveNew(index) {
      let bodyParms = {
        former: this.integrationList[index].former,
        duration: this.integrationList[index].duration,
        topics: this.integrationList[index].topics,
        day: getOnlyDate(this.integrationList[index].day)
      };

      return userService
        .createUserIntegration(this.userId, bodyParms)
        .then(response => {
          if (!response) {
            return this.$toast.add({
              severity: "error",
              summary: "Erro",
              detail: "Ocorreu um erro ao gravar",
              life: 3000
            });
          }
          this.integrationList[index] = response;
          return this.$toast.add({
            severity: "success",
            summary: "Integração Criada",
            detail: "A integração foi criada com sucesso",
            life: 3000
          });
        });
    }
  }
};
</script>
