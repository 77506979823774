<template>
  <div class="p-field p-col-12 p-md-12 p-fluid p-formgrid p-grid">
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <div class="p-field p-col-12 p-md-6">
      <h4><strong>Questões a tratar - Entrada</strong></h4>
      <div
        class="p-field p-col-12 p-md-12 "
        v-for="(category, index) in entranceCategories"
        :key="index"
      >
        <h5 class="grey-backgroud">{{ category[0].group }}</h5>
        <div
          class="p-fluid p-formgrid p-grid"
          v-for="question in category"
          :key="question.id"
        >
          <div
            v-if="question.type == 'entrance'"
            class="p-field p-col-12 p-md-11"
          >
            {{ question.question }}
          </div>
          <div
            v-if="question.type == 'entrance'"
            class="p-field p-col-12 p-md-1"
          >
            <InputSwitch
              v-model="question.answer"
              :id="question.id"
              @change="answer(question)"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="p-field p-col-12 p-md-6" v-if="exitDate">
      <h4><strong>Questões a tratar - Saída</strong></h4>
      <div
        class="p-field p-col-12 p-md-12 "
        v-for="(category, index) in exitCategories"
        :key="index"
      >
        <div
          class="p-fluid p-formgrid p-grid"
          v-for="question in category"
          :key="question.id"
        >
          <div v-if="question.type == 'exit'" class="p-field p-col-12 p-md-11">
            {{ question.question }}
          </div>
          <div v-if="question.type == 'exit'" class="p-field p-col-12 p-md-1">
            <InputSwitch
              v-model="question.answer"
              :id="question.id"
              @change="answer(question)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import checklistService from "../services/entranceExitChecklist.service";
export default {
  name: "UserEntranceExitChecklist",
  props: ["userId", "exitDate"],
  data() {
    return {
      questionsList: [],
      entranceCategories: [],
      exitCategories: []
    };
  },
  async created() {
    await this.getUserChecklist();
    this.getEntranceByCategories();
    this.getExitByCategories();
  },
  mounted() {},
  methods: {
    async getUserChecklist() {
      return checklistService.getUserChecklist(this.userId).then(response => {
        response.forEach(question => {
          if (question.answer == "true") {
            question.answer = true;
          } else {
            question.answer = false;
          }
        });
        this.questionsList = response;
      });
    },
    getEntranceByCategories() {
      return (this.entranceCategories = this.questionsList.reduce(function(
        r,
        a
      ) {
        if (a.type == "entrance") {
          r[a.id_group] = r[a.id_group] || [];
          r[a.id_group].push(a);
        }
        return r;
      },
      Object.create(null)));
    },
    getExitByCategories() {
      return (this.exitCategories = this.questionsList.reduce(function(r, a) {
        if (a.type == "exit") {
          r[a.id_group] = r[a.id_group] || [];
          r[a.id_group].push(a);
        }
        return r;
      }, Object.create(null)));
    },
    answer(question) {
      let bodyParms = {
        answer: question.answer == true ? "true" : "false"
      };
      return checklistService
        .saveAnswer(this.userId, question.id, bodyParms)
        .then(response => {
          if (!response) {
            return this.$toast.add({
              severity: "error",
              summary: "Erro",
              detail: "Ocorreu um erro ao gravar",
              life: 3000
            });
          }
          return;
        });
    }
  }
};
</script>
<style>
.grey-backgroud {
  background-color: #efefef;
}
</style>
