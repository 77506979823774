var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"p-field p-col-12 p-md-12 p-fluid p-formgrid p-grid"},[_c('Toast',{style:({ width: _vm.$isMobile() ? '85vw' : '' })}),_c('div',{staticClass:"p-field p-col-12 p-md-12"},[_vm._m(0),_c('div',{staticClass:"p-field p-col-12 p-md-12"},[_vm._l((_vm.integrationList),function(integration,index){return _c('form',{key:index,staticClass:"p-col-12 p-mt-3",attrs:{"name":'userIntegrationForm_' + index,"id":'userIntegrationForm_' + index,"data-vv-scope":'userIntegrationForm_' + index},on:{"submit":function($event){$event.preventDefault();return _vm.saveIntegration(index)}}},[_c('div',{staticClass:"p-fluid p-formgrid p-grid"},[_c('div',{staticClass:"p-field p-col-12 p-md-2"},[_c('span',{staticClass:"p-float-label"},[_c('InputText',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],class:[
                  {
                    'p-invalid': _vm.errors.has(
                      'userIntegrationForm_' + index + '.former'
                    )
                  },
                  'form-control'
                ],attrs:{"type":"text","name":'former'},model:{value:(integration.former),callback:function ($$v) {_vm.$set(integration, "former", $$v)},expression:"integration.former"}}),_c('label',{attrs:{"for":"former"}},[_vm._v("Formador")])],1),(_vm.errors.has('userIntegrationForm_' + index + '.former'))?_c('small',{staticClass:"p-error",attrs:{"role":"alert"}},[_vm._v(" Formador é obrigatório ")]):_vm._e()]),_c('div',{staticClass:"p-field p-col-12 p-md-2"},[_c('v-date-picker',{attrs:{"is-required":"","masks":{
                input: 'DD-MM-YYYY'
              }},scopedSlots:_vm._u([{key:"default",fn:function({ inputValue, inputEvents }){return [_c('span',{staticClass:"p-float-label"},[_c('input',_vm._g({directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],class:[
                      {
                        'p-error': _vm.errors.has(
                          'userIntegrationForm_' + index + '.day'
                        )
                      },
                      'form-control',
                      'p-inputtext',
                      'p-component',
                      'p-filled'
                    ],attrs:{"name":'day'},domProps:{"value":inputValue}},inputEvents)),_c('label',{attrs:{"for":"inputtext"}},[_vm._v("Dia")])]),(_vm.errors.has('userIntegrationForm_' + index + '.day'))?_c('small',{staticClass:"p-error",attrs:{"role":"alert"}},[_vm._v(" Dia é obrigatório ")]):_vm._e()]}}],null,true),model:{value:(integration.day),callback:function ($$v) {_vm.$set(integration, "day", $$v)},expression:"integration.day"}})],1),_c('div',{staticClass:"p-field p-col-12 p-md-1"},[_c('span',{staticClass:"p-float-label"},[_c('InputNumber',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],class:[
                  {
                    'p-invalid': _vm.errors.has(
                      'userIntegrationForm_' + index + '.hours'
                    )
                  },
                  'form-control'
                ],attrs:{"name":'hours',"suffix":" horas"},model:{value:(integration.duration),callback:function ($$v) {_vm.$set(integration, "duration", $$v)},expression:"integration.duration"}}),_c('label',{attrs:{"for":"hours"}},[_vm._v("N. Horas")])],1),(_vm.errors.has('userIntegrationForm_' + index + '.hours'))?_c('small',{staticClass:"p-error",attrs:{"role":"alert"}},[_vm._v(" Horas de Formação é obrigatório ")]):_vm._e()]),_c('div',{staticClass:"p-field p-col-12 p-md-6"},[_c('span',{staticClass:"p-float-label"},[_c('Textarea',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],class:[
                  {
                    'p-invalid': _vm.errors.has(
                      'userIntegrationForm_' + index + '.topics'
                    )
                  },
                  'form-control'
                ],attrs:{"type":"text","autoResize":true,"rows":"3","name":'topics'},model:{value:(integration.topics),callback:function ($$v) {_vm.$set(integration, "topics", $$v)},expression:"integration.topics"}}),_vm._v(" "),_c('label',[_vm._v("Temas abordados")])],1),(_vm.errors.has('userIntegrationForm_' + index + '.topics'))?_c('small',{staticClass:"p-error",attrs:{"role":"alert"}},[_vm._v(" Temas abordados é obrigatório ")]):_vm._e()]),_c('div',{staticClass:"p-dialog-footer"},[_c('Button',{staticClass:"p-button-success",attrs:{"label":integration.id == undefined ? 'Criar' : 'Gravar',"icon":"pi pi-check"},on:{"click":function($event){return _vm.saveIntegration(index)}}})],1),_c('divider')],1)])}),_c('div',{staticClass:"p-field p-col-12 p-md-2"},[_c('Button',{staticClass:"p-button-success",attrs:{"label":'Adicionar Novo',"icon":"pi pi-plus"},on:{"click":function($event){return _vm.addNew()}}})],1)],2)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h4',[_c('strong',[_vm._v("Ficha de Integração")])])
}]

export { render, staticRenderFns }